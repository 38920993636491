import * as React from 'react';

import { Minimize } from '@carbon/icons-react';

import CoinsortButton from 'app/components/CoinsortButtons/CoinsortButton';
import CoinsortProgress from 'app/components/CoinsortProgress/CoinsortProgress';
import ExpandedQuotaApprovalWorkflow from 'app/components/QuotaApprovalWorkflow/ExpandedQuotaApprovalWorkflow/ExpandedQuotaApprovalWorkflow';
import ExpandedPlanTargets from 'app/components/TerritoriesAndPlanTargets/ExpandedPlanTargets/ExpandedPlanTargets';
import ExpandedTerritoryDefineAndRefine from 'app/components/TerritoriesAndPlanTargets/ExpandedTerritoryDefineAndRefine/ExpandedTerritoryDefineAndRefine';

import { TerritoryGroupDialogProvider } from 'app/contexts/territoryGroupDialogProvider';

import { SplitFeatures } from 'app/global/features';

import usePhase from 'app/hooks/usePhase';
import useTreatment from 'app/hooks/useTreatment';

import { BattleCardSection, DeploymentModelPhase, SectionName } from 'app/models';

import block from 'utils/bem-css-modules';
import CanUser from 'utils/permissions/CanUser';
import { UserAction } from 'utils/permissions/userActions';

import style from './BattleCardExpandedView.module.pcss';

const b = block(style);

interface BattleCardExpandedViewProps {
  section: BattleCardSection;
  toggleViewExpandedCard: (event: React.MouseEvent, cardId: string, panelSectionName: string) => void;
  battlecardId: string;
  sectionName: string;
}

const BattleCardExpandedView: React.FC<BattleCardExpandedViewProps> = ({
  section,
  toggleViewExpandedCard,
  sectionName,
  battlecardId
}: BattleCardExpandedViewProps) => {
  const deploymentModelPhase = usePhase();
  const [matchingEngineEnabled] = useTreatment(SplitFeatures.MATCHING_ENGINE);

  const [isAddedConfigurationsForUserPermissionsEnabled] = useTreatment(
    SplitFeatures.ADDED_CONFIGURATIONS_FOR_USER_PERMISSIONS
  );

  return (
    <div className={b('expandedBody')}>
      <div className={b('expandedBodyHeader')}>
        <span className={b('expandedPanelTitle')} data-testid="expanded-panel-title">
          {deploymentModelPhase !== DeploymentModelPhase.manage && section.header}
        </span>
        {section.name === SectionName.TERRITORY_DEFINE && !isAddedConfigurationsForUserPermissionsEnabled && (
          <CanUser perform={UserAction.RUN_COINSORT} yes={<CoinsortButton />} />
        )}
        {section.name === SectionName.TERRITORY_DEFINE && isAddedConfigurationsForUserPermissionsEnabled && (
          <CanUser perform={UserAction.COINSORT_VIEW} yes={<CoinsortButton />} />
        )}
        <span
          className={b('panelViewDetailIcon')}
          data-testid={`expand-icon-${section.name}`}
          onClick={(event) => {
            toggleViewExpandedCard(event, battlecardId, sectionName);
          }}
        >
          <Minimize />
        </span>
      </div>
      <div className={b('expandedBodyContent')}>
        {section.name === SectionName.WORKFLOW && (
          <ExpandedQuotaApprovalWorkflow data-testid="expanded-quota-approval-workflow" />
        )}
        <TerritoryGroupDialogProvider>
          {section.name === SectionName.TERRITORY_DEFINE && (
            <ExpandedTerritoryDefineAndRefine
              battleCardId={battlecardId}
              data-testid="expanded-territory-define-and-refine"
            />
          )}
          {section.name === SectionName.PLAN_TARGETS && <ExpandedPlanTargets battleCardId={battlecardId} />}
        </TerritoryGroupDialogProvider>
      </div>
      {!matchingEngineEnabled && <CoinsortProgress data-testid="coin-sort-progress" />}
    </div>
  );
};

export default BattleCardExpandedView;
