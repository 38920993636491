import React, { useCallback, useEffect, useState } from 'react';

import { GridApi, RowEditingStartedEvent, ServerSideStoreType } from '@ag-grid-community/core';
import dayjs from 'dayjs';

import Dialog from 'components/Dialog/Dialog';

import AdvancedGrid from 'app/components/AdvancedGrid/AdvancedGrid';
import GridLoading from 'app/components/AdvancedGrid/GridLoading/GridLoading';
import LookupTableDetail from 'app/components/DataPanel/TablesPanel/LookupTableDetail/LookupTableDetail';

import { CELL_HEIGHT } from 'app/constants/DataTrayConstants';

import { useBattleCard } from 'app/contexts/battleCardProvider';
import { useData } from 'app/contexts/dataProvider';
import { useGrid } from 'app/contexts/gridProvider';
import { useLocalization } from 'app/contexts/localizationProvider';
import { usePlanTargets } from 'app/contexts/planTargetsProvider';
import { useScope } from 'app/contexts/scopeProvider';
import { useTerritoryDefineAndRefine } from 'app/contexts/territoryDefineAndRefineProvider';

import { SplitFeatures } from 'app/global/features';
import { BLOCK_SIZE, MEASURES_GRID_COLUMN_WIDTH_SMALL } from 'app/global/variables';

import {
  GetDataSheets_getDeploymentModelSpec_dataSheets,
  GetTerritoryRulesForFieldsVariables,
  LookupTypeEnum,
  TerritoryFieldValuesInput
} from 'app/graphql/generated/graphqlApolloTypes';
import { useGetLookupsForPlanningCycle } from 'app/graphql/hooks/useGetLookupsForPlanningCycle';
import { useUpsertFieldValues } from 'app/graphql/mutations/upsertFieldValues';
import { useGetTerritoryRulesForFieldsLazy } from 'app/graphql/queries/getTerritoryRulesForFields';

import useCanUser from 'app/hooks/useCanUser';
import usePhase from 'app/hooks/usePhase';
import useShowToast from 'app/hooks/useShowToast';
import useTreatment from 'app/hooks/useTreatment';

import {
  DefaultSheetName,
  DeploymentModelPhase,
  GridFields,
  GridHeaders,
  HierarchyType,
  SelectedQuotaDrillInTerritory,
  SheetType,
  TerritorySheetGridColumnName,
  UpsertTerritoryDateStatusType
} from 'app/models';

import block from 'utils/bem-css-modules';
import { openPreviewDialogHelper } from 'utils/helpers/sheetsPreviewUtils';
import { formatMessage } from 'utils/messages/utils';
import { UserAction } from 'utils/permissions/userActions';

import style from './TerritoryQuotaGrid.module.pcss';
import buildTerritoryQuotaGridColumnDefs from './TerritoryQuotaGridColumnDef';
import {
  generateDataSource,
  getFormattedSheetDefinitions,
  getTerritoryFieldIds,
  handleAddingNewAdjEffectiveDates,
  handleFilterInput,
  handleRefetchEditingRows,
  upsertTerritoryDate
} from './TerritoryQuotaGridUtils';

const b = block(style);

const TerritoryQuotaGrid: React.FC = () => {
  const [rowEvent, setRowEvent] = useState<null | RowEditingStartedEvent>(null);
  const [isLookupPreviewOpen, setIsLookupPreviewOpen] = useState<boolean>(false);
  const { selectedDeploymentModelId, selectedPlanningCycle, selectedTenant } = useScope();
  const { selectedPillIdPlanTargets } = usePlanTargets();
  const { selectedPillIdTDR } = useTerritoryDefineAndRefine();
  const {
    getSheetDefinitions,
    sheetDefinitions,
    sheetDefinitionsLoading,
    allDataSheets,
    setSelectedTable,
    selectedTable,
    setSelectedSheet
  } = useData();
  const { selectedQuotaComponentId, selectedBattleCardId, battleCardLookupMap, quotaBreakdownHierarchies } =
    useBattleCard();

  const {
    sortModel,
    setShowManageTerritoryQuotaDrillIn,
    setSelectedQuotaDrillInTerritory,
    refreshGrid,
    setRefreshGrid
  } = useGrid();
  const { defaultReportingCurrency } = useLocalization();
  const [gridApi, setGridApi] = useState<GridApi>(null);
  const deploymentModelPhase = usePhase();
  const showToast = useShowToast();
  const canEditTerritoryQuota = useCanUser(UserAction.TERRITORY_QUOTA_EDIT);

  const [upsertFieldValues] = useUpsertFieldValues({
    onError: () => {
      showToast(formatMessage('FAILED_TO_UPSERT_FIELD_VALUE_ERROR'), 'danger');
    }
  });

  const { planningCycleDuration, planningCycleStartDate } = selectedPlanningCycle;

  const {
    data: lookups,
    loading: lookupsLoading,
    refetch: refetchLookups
  } = useGetLookupsForPlanningCycle({
    planningCycleId: selectedPlanningCycle.id
  });

  const containerRef = React.useRef(null);

  const battleCardLocalCurrencyCode = battleCardLookupMap?.[selectedBattleCardId]?.localCurrencyCode;

  const [isAccountMoveInTerritoryQuotaGridEnabled] = useTreatment(
    SplitFeatures.AMWQ_ACCOUNT_QUOTA_IN_TERRITORY_QUOTA_GRID
  );
  const [isAccountMoveWithQuotaTwoHierarchiesEnabled] = useTreatment(SplitFeatures.AMWQ_TWO_HIERARCHIES);

  const isTQM = deploymentModelPhase === DeploymentModelPhase.manage;
  useEffect(() => {
    if (defaultTerritorySheetId) {
      setSelectedSheet(
        allDataSheets.filter(
          (sheet) =>
            sheet.sheetType === SheetType.TERRITORY_SHEET && sheet.sheetName === DefaultSheetName.TERRITORY_QUOTA_SHEET
        )?.[0] as GetDataSheets_getDeploymentModelSpec_dataSheets
      );
      getSheetDefinitions(selectedDeploymentModelId, defaultTerritorySheetId, isTQM);
    }
  }, [allDataSheets]);

  const initialStartRow = 1;
  const measureId = 0;
  const initialEndRow = BLOCK_SIZE;
  const defaultTerritorySheetId = allDataSheets.filter(
    (sheet) =>
      sheet.sheetType === SheetType.TERRITORY_SHEET && sheet.sheetName === DefaultSheetName.TERRITORY_QUOTA_SHEET
  )?.[0]?.sheetId;
  const formattedSheetDefinitions = getFormattedSheetDefinitions(
    sheetDefinitions,
    planningCycleDuration,
    planningCycleStartDate,
    selectedQuotaComponentId
  );
  const fieldIdsLookUpTable = formattedSheetDefinitions?.reduce(
    (obj, definition) => ({ ...obj, [definition?.measureId]: definition?.measureName }),
    {}
  );

  const fieldIds = getTerritoryFieldIds(fieldIdsLookUpTable);

  const selectedPillId = selectedPillIdPlanTargets || selectedPillIdTDR;

  const requestVariables = {
    quotaComponentId: selectedQuotaComponentId,
    battlecardId: +selectedBattleCardId,
    measureId,
    sorting: sortModel,
    territoryGroupId: +selectedPillId || null
  };

  const getTerritoryRulesVariables = (startRow, endRow, filters): GetTerritoryRulesForFieldsVariables => {
    return {
      ...requestVariables,
      startRow,
      endRow,
      fieldIds,
      searchInput: {
        filters
      },
      includeRedirectExists: isAccountMoveInTerritoryQuotaGridEnabled
    };
  };

  const handleOnGridReady = (gridEvent) => {
    const fetchMoreVariables = {
      quotaComponentId: +selectedQuotaComponentId,
      battlecardId: +selectedBattleCardId,
      measureId,
      sorting: sortModel,
      totalCount,
      fieldIdsLookUpTable,
      fieldIds,
      includeRedirectExists: isAccountMoveInTerritoryQuotaGridEnabled
    };
    const dataSource = generateDataSource(
      fetchMore,
      fetchMoreVariables,
      planningCycleDuration,
      planningCycleStartDate,
      selectedDeploymentModelId,
      isAccountMoveInTerritoryQuotaGridEnabled
    );
    gridEvent.api.setServerSideDatasource(dataSource);
    setGridApi(gridEvent.api);
  };

  const [getTerritoryRules, { data: territoryRulesData, loading: territoryRulesDataLoading, fetchMore }] =
    useGetTerritoryRulesForFieldsLazy({
      variables: getTerritoryRulesVariables(initialStartRow, initialEndRow, null)
    });

  const isLoading = sheetDefinitionsLoading || territoryRulesDataLoading;

  useEffect(() => {
    getTerritoryRules();
  }, [selectedBattleCardId, selectedQuotaComponentId, selectedQuotaComponentId, selectedPillId]);

  useEffect(() => {
    if (refreshGrid) {
      gridApi?.refreshServerSideStore({ purge: false });
      setRefreshGrid(false);
    }
  }, [refreshGrid]);

  const openedRowGroups = [];
  const totalCount = territoryRulesData?.getTerritoryRules?.totalCount || 0;

  const gridOptions = {
    headerHeight: CELL_HEIGHT,
    rowHeight: CELL_HEIGHT,
    rowModelType: 'serverSide',
    serverSideStoreType: 'partial' as ServerSideStoreType,
    cacheBlockSize: BLOCK_SIZE,
    infiniteInitialRowCount: totalCount,
    onGridReady: handleOnGridReady,
    onRowGroupOpened(event) {
      const nodeData = event?.node?.data;
      if (event?.expanded) {
        event.api.refreshCells({ force: true });
        // This function is to find the added the opened row groups to the array that stores all the opened rows group
        // when user decides to close the row groups and the row groups is not already opened
        if (!openedRowGroups.includes(nodeData.territoryId)) {
          openedRowGroups.push(nodeData.territoryId);
        }
      } else {
        // This function is to find the closed row groups and remove it from the array that stores all the opened rows groups
        // when user decides to close the row groups
        openedRowGroups.splice(
          openedRowGroups.findIndex((territoryId) => territoryId === nodeData.territoryId),
          1
        );
        event.api.refreshCells({ force: true });
      }
    },
    isServerSideGroupOpenByDefault(params) {
      return openedRowGroups.includes(params.rowNode.data.territoryId);
    }
  };

  const onRowEditingStarted = useCallback((event) => {
    // save copy of row event to manually call stopEditing if user clicks outside grid
    setRowEvent(event);
  }, []);

  const onRowDoubleClicked = useCallback((event) => {
    if (event.node.level > 0 && !event.data.rangeId) {
      event.api?.stopEditing();
    }
  }, []);

  const onCellValueChanged = useCallback(
    (event) => {
      const field = event.colDef.field;
      const fieldId = Object.keys(fieldIdsLookUpTable).find((key) => {
        return fieldIdsLookUpTable[key] === field;
      });
      const newValue = event.newValue;
      const oldValue = event.oldValue;
      if (newValue === oldValue || !newValue) {
        return;
      }

      if (!event.node.changedValues) {
        event.node.changedValues = [];
      }

      const value = {
        fieldValue: event.newValue,
        fieldName: field,
        fieldId: +fieldId
      };

      event.node.changedValues.push(event.data.rangeId ? { ...value, rangeId: event.data.rangeId } : value);
    },
    [fieldIdsLookUpTable]
  );

  const handleRefetchRowData = async (params) => {
    const nonAdjEffectiveDateColumns = params.node.changedValues.some(
      (value) =>
        value.fieldName !== TerritorySheetGridColumnName.TERRITORY_QUOTA_ADJUSTMENT &&
        value.fieldName !== TerritorySheetGridColumnName.ADJUSTMENT_EFFECTIVE_DATE
    );

    const isEditingMode = (nonAdjEffectiveDateColumns && params.node.level === 0) || params.data.rangeId;
    // handle adding quota adjustment and effective date

    if (isEditingMode) {
      await handleEditingForAdjEffectiveDates(params);
    } else {
      await handleAddingNewAdjEffectiveDates(params);
    }
  };

  const getTerritoryQuotaBreakdownVariables = (ruleId, filters) => {
    const rangeFieldInput = {
      deploymentModelId: selectedDeploymentModelId,
      ruleId,
      rangeFieldName: TerritorySheetGridColumnName.TERRITORY_QUOTA_ADJUSTMENT
    };

    return {
      startRow: 1,
      endRow: 1,
      ...requestVariables,
      rangeFieldInput,
      searchInput: {
        filters
      }
    };
  };

  const handleEditingForAdjEffectiveDates = async (params) => {
    const territoryId = params.data.territoryId || params.data.territoryParentId;
    const territoryName = params.data.territoryName || params.data.territoryParentName;
    const filters = handleFilterInput(territoryId, territoryName);

    const refetchQuotaBreakdownVariables = getTerritoryQuotaBreakdownVariables(params.data.ruleId, filters);
    const refetchTerritoryRuleVariables = getTerritoryRulesVariables(1, 1, filters);
    const formatInput = {
      planningCycleStartDate,
      planningCycleDuration,
      fieldIdsLookUpTable
    };
    const variables = {
      refetchQuotaBreakdownVariables,
      refetchTerritoryRuleVariables
    };

    await handleRefetchEditingRows(variables, params, fetchMore, formatInput, isAccountMoveInTerritoryQuotaGridEnabled);
  };

  const handleUpsertFieldValues = async (params, fieldValueListForUpsert) => {
    const data = params.data;
    const rangeField = {
      effectiveStartDate: data.effectiveStartDate ? dayjs(data.effectiveStartDate).format('YYYY-MM-DD') : null,
      effectiveEndDate: data.effectiveEndDate ? dayjs(data.effectiveEndDate).format('YYYY-MM-DD') : null,
      sheetId: defaultTerritorySheetId
    };

    // Need to filter out adjustment effective date because the value for adjustment effective date
    // is updated in rangeField variables along with quota adjustment value
    const fieldValueListWithoutAdjEffectiveDates = fieldValueListForUpsert.filter(
      (field) =>
        field.fieldName !== TerritorySheetGridColumnName.ADJUSTMENT_EFFECTIVE_DATE &&
        field.fieldName !== TerritorySheetGridColumnName.TERRITORY_QUOTA_ADJUSTMENT
    );

    const shouldUpdateAdjEffectiveDates = fieldValueListForUpsert.some(
      (field) =>
        field.fieldName === TerritorySheetGridColumnName.ADJUSTMENT_EFFECTIVE_DATE ||
        field.fieldName === TerritorySheetGridColumnName.TERRITORY_QUOTA_ADJUSTMENT
    );

    const rangeFieldInput = data.rangeId ? { ...rangeField, rangeId: data.rangeId } : rangeField;
    const territoryId = data.territoryId || data.territoryParentId;

    const input: TerritoryFieldValuesInput[] = [];

    const variables = {
      battlecardId: +selectedBattleCardId,
      quotaComponentId: +selectedQuotaComponentId,
      territoryGroupId: data.territoryGroupId,
      territoryId,
      ruleId: data.ruleId,
      isTQM: true
    };

    fieldValueListWithoutAdjEffectiveDates.forEach(({ fieldId, fieldValue }) => {
      input.push({
        ...variables,
        fieldId,
        fieldValue
      });
    });

    if (shouldUpdateAdjEffectiveDates) {
      // In case there is no selected value for adjustment effective date and territory quota adjustment, return early
      // and show error message
      const territoryQuotaAdjustmentValue = data[TerritorySheetGridColumnName.TERRITORY_QUOTA_ADJUSTMENT];
      if (!rangeField.effectiveStartDate || !rangeField.effectiveEndDate || !territoryQuotaAdjustmentValue) {
        showToast(formatMessage('MISSING_EFFECTIVE_DATING_FIELD_ERROR'), 'danger');
        return null;
      }

      input.push({
        ...variables,
        fieldId: +Object.keys(fieldIdsLookUpTable).find(
          (key) => fieldIdsLookUpTable[key] === TerritorySheetGridColumnName.TERRITORY_QUOTA_ADJUSTMENT
        ),
        fieldValue: territoryQuotaAdjustmentValue,
        rangeField: rangeFieldInput
      });
    }

    return (
      input.length > 0 &&
      (await upsertFieldValues({
        variables: {
          input: {
            territoryFieldValues: input
          }
        }
      }))
    );
  };

  const onRowValueChanged = useCallback(
    async (params) => {
      const valueListForUpdates = params.node.changedValues;
      if (!valueListForUpdates) {
        return;
      }

      const fieldValueListWithoutTerritoryEffectiveDates = valueListForUpdates.filter(
        (field) => field.fieldName !== TerritorySheetGridColumnName.TERRITORY_EFFECTIVE_DATES
      );

      const shouldSkipUpdateTerritoryEffectiveDate = valueListForUpdates.find(
        (item) => item.fieldName === TerritorySheetGridColumnName.TERRITORY_EFFECTIVE_DATES
      );

      let upsertTerritoryFieldStatus = null;
      // In case there is no change in territory effective date, should skip upsert date call
      // call upsert field values only
      if (!shouldSkipUpdateTerritoryEffectiveDate) {
        upsertTerritoryFieldStatus = await handleUpsertFieldValues(
          params,
          fieldValueListWithoutTerritoryEffectiveDates
        );
        return upsertTerritoryFieldStatus && (await handleRefetchRowData(params));
      }

      const isUpsertTerritoryDateSuccessful = await upsertTerritoryDate(params, +selectedQuotaComponentId);
      if (isUpsertTerritoryDateSuccessful === UpsertTerritoryDateStatusType.SUCCESS) {
        params.api.forEachNode((node) => {
          if (node.id === params.node.id) {
            params.api.setRowNodeExpanded(node, true);
          }
        });
        if (fieldValueListWithoutTerritoryEffectiveDates.length === 0) {
          return await handleRefetchRowData(params);
        }

        upsertTerritoryFieldStatus = await handleUpsertFieldValues(
          params,
          fieldValueListWithoutTerritoryEffectiveDates
        );
        return upsertTerritoryFieldStatus && (await handleRefetchRowData(params));
      }

      //In case upsert for territory date failed, return early
      if (isUpsertTerritoryDateSuccessful === UpsertTerritoryDateStatusType.FAIL) {
        return;
      }
    },
    [fieldIdsLookUpTable, selectedQuotaComponentId]
  );

  useEffect(() => {
    // only listen for clicks if grid is editing mode
    if (rowEvent) {
      document.addEventListener('click', clickHandler, true);
    } else {
      document.removeEventListener('click', clickHandler, true);
    }

    return () => {
      document.removeEventListener('click', clickHandler, true);
    };
  }, [rowEvent]);

  const clickHandler = useCallback((event: MouseEvent) => {
    // get grid and datatray elements and check if click is in them
    const gridElement = document.getElementsByClassName('ag-center-cols-container')[0];
    const dataTrayElement = document.getElementsByClassName('bp3-drawer')[0];
    const inGrid = event?.composedPath().includes(gridElement);

    const inDataTray = event?.composedPath().includes(dataTrayElement);

    // if user clicks in the grid they may editing fields, so stop editing when click is in datatray but not grid
    if (!inGrid && inDataTray) {
      setRowEvent((prevRowEvent) => {
        prevRowEvent?.api?.stopEditing();
        return prevRowEvent;
      });
    }
  }, []);

  const cellClassNames = [b('rightAlignedCell'), b('highlightOnHoverCell'), b('boldedTextCell'), b('redHighlight')];
  const headerClassNames = [b('header'), b('rightAlignedHeader')];

  const handleOpenPreviewDialog = (): void => {
    openPreviewDialogHelper(
      lookups,
      selectedTable,
      setSelectedTable,
      setIsLookupPreviewOpen,
      selectedTenant.id,
      selectedPlanningCycle.id,
      LookupTypeEnum.Seasonality
    );
  };

  const onRevisedQuotaClicked = (selectedQuotaTerritory: SelectedQuotaDrillInTerritory): void => {
    setShowManageTerritoryQuotaDrillIn(true);
    setSelectedQuotaDrillInTerritory(selectedQuotaTerritory);
  };

  const isAccountMoveWithQuotaMoveSettingEnabled =
    quotaBreakdownHierarchies?.length >= 1 &&
    quotaBreakdownHierarchies.some((hierarchy) => hierarchy.hierarchyType === HierarchyType.CustomerAccountHierarchy);

  const isAccountMoveWithQuotaMoveFFEnabled =
    isAccountMoveInTerritoryQuotaGridEnabled || isAccountMoveWithQuotaTwoHierarchiesEnabled;

  if (lookupsLoading) {
    return (
      <div data-testid="lookups-loading" ref={containerRef}>
        <GridLoading gridHeight={containerRef?.current?.offsetHeight} gridWidth={containerRef?.current?.offsetWidth} />
      </div>
    );
  }

  const handleLookupRowUpserted = () => {
    refetchLookups();
    getSheetDefinitions(selectedDeploymentModelId, defaultTerritorySheetId, isTQM);
  };

  return (
    <div className={b('gridWrapper')} ref={containerRef}>
      {sheetDefinitionsLoading || sheetDefinitions?.length ? (
        <div className={b('advancedGridWrapper')}>
          <AdvancedGrid
            data-testid="territory-quota-grid"
            gridOptions={gridOptions}
            columnDefs={buildTerritoryQuotaGridColumnDefs({
              sheetDefinitions: formattedSheetDefinitions,
              currency: battleCardLocalCurrencyCode || defaultReportingCurrency,
              headerClassNames,
              cellClassNames,
              lookups,
              fieldIdsLookUpTable,
              selectedQuotaComponentId,
              previewDialogOpener: handleOpenPreviewDialog,
              canEditTerritoryQuota,
              onRevisedQuotaClicked,
              isAccountMoveWithQuotaMoveSettingEnabled,
              isAccountMoveWithQuotaMoveFFEnabled
            })}
            gridWidth={containerRef?.current?.offsetWidth}
            gridHeight={containerRef?.current?.offsetHeight}
            showGridLoading={isLoading}
            editType={'fullRow'}
            onRowValueChanged={onRowValueChanged}
            onCellValueChanged={onCellValueChanged}
            onRowEditingStarted={onRowEditingStarted}
            onRowDoubleClicked={onRowDoubleClicked}
            suppressCellSelection
            suppressRowTransform
            treeData
            enableGroupEdit={true}
            autoGroupColumnDef={{
              headerName: GridHeaders.TERRITORY_ID,
              headerClass: headerClassNames[0],
              field: GridFields.TERRITORY_ID,
              minWidth: MEASURES_GRID_COLUMN_WIDTH_SMALL,
              flex: 1,
              resizable: true,
              pinned: 'left'
            }}
            isServerSideGroup={(dataItem) => {
              // when FF is enabled and QBT setting for the BC is only customer account hierarchy, the grid should not be grouped
              // Account move with quota in the account quota grid will replace the effective dating of quota adjustment
              // breakdown of the quota adjustment is available in the drill in grid by clicking on the revised territory quota column
              if (isAccountMoveInTerritoryQuotaGridEnabled && isAccountMoveWithQuotaMoveSettingEnabled) {
                return false;
              }

              // when QBT setting for the BC is other hierarchy combinations, the grid should be grouped
              // effective of quota adjustment is available in the grid
              const isEffectiveDatingOfQuotaAdjExisted =
                dataItem[TerritorySheetGridColumnName.ADJUSTMENT_EFFECTIVE_DATE] &&
                dataItem[TerritorySheetGridColumnName.TERRITORY_QUOTA_ADJUSTMENT];
              return !!isEffectiveDatingOfQuotaAdjExisted;
            }}
            getServerSideGroupKey={(dataItem) => {
              return dataItem.ruleId;
            }}
          />
        </div>
      ) : (
        <div className={b('gridOverlayContainer')} data-testid="no-territories-overlay">
          <div className={b('gridOverlayText')}>{formatMessage('EMPTY_GRID')}</div>
        </div>
      )}
      <Dialog
        title={formatMessage('SEASONALITY_SCHEDULES')}
        isOpen={isLookupPreviewOpen}
        confirmButtonText={formatMessage('CLOSE')}
        showCancel={false}
        style={{ minWidth: '1200px', width: '60%' }}
        onSubmit={() => setIsLookupPreviewOpen(false)}
      >
        <span className={b('lookupPreviewContents')}>
          <LookupTableDetail showHeader={false} onRowUpserted={handleLookupRowUpserted} />
        </span>
      </Dialog>
    </div>
  );
};

export default TerritoryQuotaGrid;
